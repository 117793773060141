<script setup lang='ts'>
const errorText = '未找到相关页面'

const router = useRouter()
</script>

<template>
  <div text-center p-10>
    <i class="i-custom-undraw-404?bg text-50" />
    <div text-center mt-3 text-gray-5>
      {{ errorText }}
    </div>

    <div flex gap-4 justify-center mt-10>
      <NuxtLink to="/" class="btn-primary tracking-0.5">
        回到首页
      </NuxtLink>
      <button class="btn-waring tracking-0.5" @click="router.back()">
        回到上一页
      </button>
    </div>
  </div>
</template>
